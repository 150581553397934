<script lang="ts">
    import MarketplaceNav from '../../Pages/marketPlace/MarketplaceNav.svelte';
    import { Link } from '@inertiajs/svelte';
    import toast from 'svelte-french-toast';
    import axios from 'axios';

    export let items;

    let showPopup = false;
    let selectedItem = null;
    let isLoading = false;

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    function openPurchasePopup(itemId, price) {
        selectedItem = { id: itemId, price };
        showPopup = true;
    }

    function closePopup() {
        showPopup = false;
        selectedItem = null;
    }

    function submitPurchaseForm() {
        if (!selectedItem) return;

        // Show page loader
        isLoading = true;

        const { id: itemId, price } = selectedItem;
        const postSubmitButton = document.querySelector(`#marketPostSubmitButton-${itemId}`);
        postSubmitButton.querySelector('.buttonText').style.display = 'none';

        let values = { item_id: itemId };

        axios.post('/post-market/purchase-post', values).then((response) => {
            toast.success('Congrats! Post has been purchased');

            // Get the current item from items array based on the purchased itemId
            const purchasedItemIndex = items.findIndex(item => item.id === itemId);
            if (purchasedItemIndex !== -1) {
                // Reduce the supply limit by 1 if it's more than 1
                if ((items[purchasedItemIndex].post.supply_limit ?? 0) - (items[purchasedItemIndex].post.sold_count ?? 0) > 1) {
                    items[purchasedItemIndex].post.sold_count = (items[purchasedItemIndex].post.sold_count ?? 0) + 1;
                    items = [...items]; // Trigger reactivity by reassigning the array
                } else {
                    // If supply is 1 or less, completely remove the item from the array
                    // This will remove the item from the display when the array is reassigned
                    items = items.filter(item => item.id !== itemId);
                }
            }

            postSubmitButton.disabled = false;
            postSubmitButton.querySelector('.buttonText').style.display = 'inline-block';

        }).catch((error) => {
            console.log(error);
            const errorMessage = error.response?.data || 'An error occurred.';
            toast.error(errorMessage);

            postSubmitButton.disabled = false;
            postSubmitButton.querySelector('.spinner').style.display = 'none';
            postSubmitButton.querySelector('.buttonText').style.display = 'inline-block';
        }).finally(() => {
            closePopup();
            // Hide the page loader
            isLoading = false;
        });
    }
</script>

<svelte:head>
    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
</svelte:head>

<svelte:component this="{MarketplaceNav}" />

<div class="min-h-screen bg-gray-50 p-6">
    <header class="mb-8">
        <h1 class="text-3xl font-bold text-gray-900">Post Market</h1>
        <p class="text-gray-600 mt-2">Discover and shop unique posts from creators worldwide.</p>
    </header>
<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
    {#if items.length > 0}
        {#each items as item}
            <div class="bg-white rounded-lg shadow-md overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl border border-gray-100" id="{'wrapped-div-' + item.id}">
                <div class="relative h-40 overflow-hidden">
                    <img
                      class="absolute inset-0 w-full h-full object-cover"
                      src="{ (item.post.attachments && item.post.attachments.length > 0) ? item.post.attachments[0].thumbnail : item.post.user.avatar }"
                      alt="{item.post.title || 'Untitled Post'}"
                      on:error={(e) => e.target.src = 'https://via.placeholder.com/200x200'}
                    />
                    <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                    <h3 class="absolute bottom-2 left-2 text-white font-semibold text-sm line-clamp-1">
                      {item.post.title ? item.post.title.slice(0, 20) : 'Untitled Post'}
                    </h3>
                  </div>

                <div class="p-3">
                    <p class="text-gray-600 text-xs mt-1 line-clamp-1">Impression: (from Druid)</p>
                    <p class="text-lg font-bold text-indigo-600 mt-1">${item.post.price}</p>
                    <p class="text-gray-600 text-xs mt-1 line-clamp-1">
                        {#if (item.post.supply_limit ?? 0) - (item.post.sold_count ?? 0) > 0}
                          Supply limit left: {(item.post.supply_limit ?? 0) - (item.post.sold_count ?? 0)}
                        {:else}
                          Sold Out
                        {/if}
                      </p>

                    <div class="mt-2 flex justify-between items-center text-xs">
                        <Link href="{`/${item.post.user.username}`}" class="text-indigo-600 hover:text-indigo-800 font-medium">View creator Profile</Link>
                        <form class="purchase-form" id="{'post-form-' + item.id}">
                            <input type="number" name="market_post_id" value="{item.post.id}" hidden />
                            <button class="post-buy-now-btn bg-indigo-600 text-white px-3 py-1 rounded-md hover:bg-indigo-700 transition duration-300 text-xs" type="button" on:click={() => openPurchasePopup(item.id, item.post.price)} data-post-form-id="{item.id}" id="{'marketPostSubmitButton-' + item.id}">
                                <span id="{'spinner_' + item.id}" class="spinner hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" class="animate-spin">
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path d="M15 4v7h5V4h-5zM4 15h5v5H4v-5zM4 4v5h5V4H4zm11 11h5v5h-5v-5z" fill="currentColor"></path>
                                        <path d="M8 8v8h8V8H8zm7 7H9V9h6v6z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                <span class="buttonText">Buy Now</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        {/each}
    {:else}
        <div class="col-span-full text-center py-12 bg-white rounded-lg shadow-md">
            <p class="text-gray-800 text-xl font-semibold">No posts available for sale.</p>
            <p class="text-gray-600 mt-2">Check back later for new listings!</p>
        </div>
    {/if}
</div>

</div>

{#if isLoading}
<div class="page-loader">
    <div class="loader-spinner"></div>
</div>
{/if}

{#if showPopup && selectedItem}
    <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white rounded-xl shadow-2xl max-w-sm w-full p-5 transform transition-all duration-300 scale-100 hover:scale-105">
            <div class="flex items-center justify-between mb-4">
                <h2 class="text-xl font-bold text-gray-800">Confirm Purchase</h2>
                <button on:click={closePopup} class="text-gray-500 hover:text-gray-700 focus:outline-none p-1 rounded-full">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>

        <div class="mb-4">
            <p class="text-gray-600 text-sm">Are you sure you want to purchase this post for <span class="font-semibold text-indigo-600">${selectedItem.price}</span>?</p>
        </div>

        <div class="flex justify-end space-x-3">
            <button on:click={closePopup} class="px-3 py-1 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out text-sm">Cancel</button>
            <button on:click={submitPurchaseForm} class="px-3 py-1 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 transition duration-150 ease-in-out text-sm">Confirm</button>
        </div>
    </div>
</div>

{/if}

<style>
    .spinner svg { animation: spin 1s linear infinite; }
    @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
    .line-clamp-1 { display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; }

    /* Page loader styles */
    .page-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .loader-spinner {
        width: 50px;
        height: 50px;
        border: 5px solid rgba(79, 70, 229, 0.2);
        border-radius: 50%;
        border-top-color: #4f46e5;
        animation: loader-spin 1s linear infinite;
    }

    @keyframes loader-spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>